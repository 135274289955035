'use client';

import classNames from 'classnames';

import { Link } from '../../../../../i18n';
import { type NavigationItem } from '../../../../../utils/types/navigation-items';

import styles from './sub-menu.module.scss';

type SubMenuProps = {
  isActive: boolean;
  navItem: NavigationItem;
  onClose?: () => void;
};

export const SubMenu = ({ isActive, navItem, onClose }: SubMenuProps) => {
  return (
    <ul
      className={classNames(styles['sub-menu'], {
        [styles['sub-menu--active']]: isActive,
      })}
    >
      {navItem.subMenu?.map(
        (subMenuItem) =>
          subMenuItem.visible && (
            <li key={subMenuItem.title}>
              <Link
                className={styles['sub-menu__item']}
                href={navItem.route + subMenuItem.route}
                onClick={onClose}
              >
                {subMenuItem.title}
              </Link>
            </li>
          )
      )}
    </ul>
  );
};
