'use client';

// eslint-disable-next-line import/no-unassigned-import
import 'react-multi-carousel/lib/styles.css';

import classNames from 'classnames';
import {
  Children,
  cloneElement,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFocusVisible } from 'react-aria';
import CarouselComponent, {
  type CarouselProps as RMCProps,
  type ResponsiveType,
} from 'react-multi-carousel';

import { usePreventVerticalScroll } from '@shared/utils';

import { ContentContainer } from '../content-container';

import { CarouselArrowButtons } from './components/carousel-arrow-buttons';
import styles from './carousel.module.scss';

type CarouselProps = PropsWithChildren<
  Omit<RMCProps, 'responsive'> & {
    arrowsClassName?: string;
    className?: string;
    containerClassName?: string;
    containerContentTestId?: string;
    filterAreaNode?: ReactNode;
    headerClassName?: string;
    isSecondary?: boolean;
    itemClassName?: string;
    resetSlideOnChildrenChange?: boolean;
    responsive?: ResponsiveType;
    title?: string;
    withDefaultResponsiveConfig?: boolean;
  }
>;

const getResponsiveItemsAndGutter = ({
  children,
  count,
}: {
  children: ReactNode;
  count: number;
}) => ({
  items: count,
  partialVisibilityGutter: Array.isArray(children) && children.length <= count ? 0 : 30,
  slidesToSlide: count,
});

const getDefaultResponsiveConfig = (children: ReactNode) => ({
  desktop: {
    breakpoint: { max: 1919, min: 1600 },
    ...getResponsiveItemsAndGutter({
      children,
      count: 5,
    }),
  },
  desktopExtraWide: {
    breakpoint: {
      max: Number.POSITIVE_INFINITY,
      min: 2300,
    },
    ...getResponsiveItemsAndGutter({
      children,
      count: 7,
    }),
  },
  desktopWide: {
    breakpoint: { max: 2299, min: 1920 },
    ...getResponsiveItemsAndGutter({
      children,
      count: 6,
    }),
  },
  laptop: {
    breakpoint: { max: 1199, min: 940 },
    ...getResponsiveItemsAndGutter({
      children,
      count: 3,
    }),
  },
  laptopWide: {
    breakpoint: { max: 1599, min: 1200 },
    ...getResponsiveItemsAndGutter({
      children,
      count: 4,
    }),
  },
  mobile: {
    breakpoint: { max: 599, min: 410 },
    ...getResponsiveItemsAndGutter({
      children,
      count: 1,
    }),
  },
  mobileNarrow: {
    breakpoint: { max: 409, min: 0 },
    ...getResponsiveItemsAndGutter({
      children,
      count: 1,
    }),
  },
  tablet: {
    breakpoint: { max: 939, min: 600 },
    ...getResponsiveItemsAndGutter({
      children,
      count: 2,
    }),
  },
});

export const Carousel = ({
  arrowsClassName,
  children,
  className,
  containerClassName,
  containerContentTestId,
  deviceType,
  filterAreaNode,
  headerClassName,
  isSecondary,
  itemClassName,
  resetSlideOnChildrenChange,
  responsive,
  title,
  withDefaultResponsiveConfig = true,
  ...rest
}: CarouselProps) => {
  const carouselRef = useRef<CarouselComponent & { isAnimationAllowed: boolean }>(null);
  const [notEnoughChildren, setNotEnoughChildren] = useState<boolean>();
  const [slidesToShow, setSlidesToShow] = useState<number>(0);
  const { isFocusVisible } = useFocusVisible();
  const [currentSlide, setCurrentSlide] = useState(0);

  const defaultResponsiveConfig = getDefaultResponsiveConfig(children);
  const responsiveConfig = responsive;

  for (const key in responsiveConfig) {
    if (responsiveConfig) {
      const config = responsiveConfig?.[key];

      if (config) {
        const { items, ...restConfig } = config;
        responsiveConfig[key] = {
          ...getResponsiveItemsAndGutter({
            children,
            count: items,
          }),
          ...restConfig,
        };
      }
    }
  }

  const CarouselInnerComponentForRerender = () => {
    useEffect(() => {
      if (carouselRef.current) {
        const notEnoughChildrenNew =
          carouselRef.current.state.totalItems <= carouselRef.current.state.slidesToShow;

        if (notEnoughChildren !== notEnoughChildrenNew) {
          setNotEnoughChildren(notEnoughChildrenNew);
        }

        if (carouselRef.current.state.slidesToShow !== slidesToShow) {
          setSlidesToShow(carouselRef.current.state.slidesToShow);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselRef.current?.state.totalItems, carouselRef.current?.state.slidesToShow]);

    return null;
  };

  const handleSlideChange = (_: number, state: { currentSlide: number }) => {
    setCurrentSlide(state.currentSlide);
  };

  const handleElementFocused = (elementIndex: number) => {
    if (carouselRef.current && !notEnoughChildren && isFocusVisible) {
      const totalItems = carouselRef.current.state.totalItems;
      const maxSlideIndex = totalItems - slidesToShow;
      const targetSlide = Math.min(elementIndex, maxSlideIndex);

      carouselRef.current.isAnimationAllowed = true;
      carouselRef?.current?.setState?.({
        currentSlide: targetSlide,
        transform: -(carouselRef?.current?.state?.itemWidth * targetSlide),
      });
    }
  };

  usePreventVerticalScroll(carouselRef.current?.containerRef);

  useEffect(() => {
    if (resetSlideOnChildrenChange && carouselRef.current) {
      carouselRef.current.goToSlide(0);
    }
  }, [children, resetSlideOnChildrenChange]);

  return (
    <div className={styles.wrapper} data-ga-carousel-container-wrapper>
      <div
        className={classNames(className, styles['carousel__wrapper'], {
          [styles['carousel--secondary']]: isSecondary,
        })}
        data-testid={containerContentTestId}
      >
        <ContentContainer>
          <div
            className={classNames(
              styles['carousel__header-container'],
              {
                [styles['carousel__header-container--secondary']]: isSecondary,
              },
              headerClassName
            )}
          >
            <div className={classNames(styles['carousel__header'])}>
              {title && (
                <h2
                  className={classNames(styles['carousel__title'], {
                    [styles['carousel__title--secondary']]: isSecondary,
                  })}
                >
                  {title}
                </h2>
              )}
              {filterAreaNode}
            </div>
            {!notEnoughChildren && (
              <CarouselArrowButtons
                carouselRef={carouselRef}
                className={arrowsClassName}
                currentSlide={currentSlide}
                isSecondary={isSecondary}
                slidesToShow={slidesToShow}
              />
            )}
          </div>
        </ContentContainer>
        <CarouselComponent
          afterChange={handleSlideChange}
          arrows={false}
          containerClass={classNames(styles['carousel__container'], containerClassName)}
          customButtonGroup={<CarouselInnerComponentForRerender />}
          deviceType={!deviceType && withDefaultResponsiveConfig ? 'desktop' : deviceType}
          itemClass={classNames(styles['carousel__item-wrapper'], itemClassName)}
          keyBoardControl={false}
          partialVisible
          ref={carouselRef}
          renderButtonGroupOutside
          responsive={{
            ...(withDefaultResponsiveConfig && defaultResponsiveConfig),
            ...responsiveConfig,
          }}
          ssr
          {...rest}
        >
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Children.map(children, (child: any, index) =>
              cloneElement(child, { onFocus: () => handleElementFocused(index) })
            )
          }
        </CarouselComponent>
      </div>
    </div>
  );
};
