'use client';

import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';

import { ADB2CUserGroup, useSession } from '@shared/misc';

type UserContextValue = {
  accessToken: string | undefined;
  groups: string[] | undefined;
  hasCompanyAdminRole: boolean | undefined;
  hasStaffRole: boolean | undefined;
  personId: number | undefined;
  status: 'authenticated' | 'loading' | 'unauthenticated';
  userId: string | undefined;
};

const UserContext = createContext<UserContextValue | undefined>(undefined);

export const UserContextProvider = ({
  children,
  userId,
}: PropsWithChildren<{ userId: string | undefined }>) => {
  const { data, status } = useSession();

  const groups = data?.groups;
  const isAuthenticated = status === 'authenticated';
  const hasStaffRole = isAuthenticated && groups?.includes(ADB2CUserGroup.MDRTStaff);
  const hasCompanyAdminRole = isAuthenticated && groups?.includes(ADB2CUserGroup.CompanyAdmin);

  const value: UserContextValue = useMemo(
    () => ({
      accessToken: data?.accessToken,
      groups,
      hasCompanyAdminRole,
      hasStaffRole,
      personId: data?.personId,
      status,
      userId,
    }),
    [data?.accessToken, data?.personId, groups, hasCompanyAdminRole, hasStaffRole, status, userId]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const value = useContext(UserContext);

  if (!value) {
    throw new Error(`${useUserContext.name} must be used inside of ${UserContextProvider.name}`);
  }

  return value;
};
