'use client';

import classNames from 'classnames';
import { cloneElement, type ReactElement } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

import { Button, ButtonVariant, Menu } from '@shared/ui-components';

import { Drawer } from '../../../drawer';

import styles from './modal-trigger.module.scss';

export const ModalTrigger = ({
  children,
  isSecondaryTheme,
}: {
  children: (close: () => void) => ReactElement;
  isSecondaryTheme: boolean;
}) => {
  const state = useOverlayTriggerState({});
  const { overlayProps, triggerProps } = useOverlayTrigger({ type: 'dialog' }, state);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onPress, ...restTriggerProps } = triggerProps;

  return (
    <div className={styles['modal-trigger']}>
      <div
        className={classNames(styles['modal-trigger__actions'], {
          [styles['modal-trigger__actions--is-theme-secondary']]: isSecondaryTheme,
        })}
      >
        <Button
          {...restTriggerProps}
          aria-label="Menu"
          className={styles['modal-trigger__button']}
          dataTestId="mobile-open-navigation-button"
          onClick={() => state.setOpen(true)}
          variant={ButtonVariant.Plain}
        >
          <Menu />
        </Button>
      </div>
      {state.isOpen && (
        <Drawer data-ga-mobile-navbar-main state={state}>
          {children && cloneElement(children(state.close), { ...overlayProps })}
        </Drawer>
      )}
    </div>
  );
};
