'use client';

import {
  GTM_EVENT_LABEL_ANDROID_APP,
  GTM_EVENT_NAME_APP_DOWNLOAD,
  sendGtmEvent,
} from '@shared/utils';

import { FALLBACK_LOCALES_LIST } from '../../../utils';
import { Locale } from '../../../utils/enums/locale';

const handleSendGTMEvent = () =>
  sendGtmEvent({
    eventName: GTM_EVENT_NAME_APP_DOWNLOAD,
    label: GTM_EVENT_LABEL_ANDROID_APP,
  });

const googlePlayBadges = [
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
    locale: Locale.En,
  },
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png',
    locale: Locale.Es,
  },
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/id_badge_web_generic.png',
    locale: Locale.Id,
  },
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/vi_badge_web_generic.png',
    locale: Locale.Vi,
  },
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/th_badge_web_generic.png',
    locale: Locale.Th,
  },
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png',
    locale: Locale.Ja,
  },
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/zh-cn_badge_web_generic.png',
    locale: Locale.ZhCN,
  },
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/zh-hk_badge_web_generic.png',
    locale: Locale.ZhCHT,
  },
  {
    imgSrc:
      'https://play.google.com/intl/en_us/badges/static/images/badges/ko_badge_web_generic.png',
    locale: Locale.Ko,
  },
];

export const GooglePlayBadge = ({
  badgeAltText,
  className,
  locale,
  url,
}: {
  badgeAltText: string;
  className?: string;
  locale: string;
  url: string;
}) => {
  const badgeUrl = FALLBACK_LOCALES_LIST.includes(locale)
    ? googlePlayBadges.find((item) => item.locale === locale)?.imgSrc
    : googlePlayBadges[0]?.imgSrc;

  return (
    <a
      className={className}
      href={url}
      onAuxClick={handleSendGTMEvent}
      onClick={handleSendGTMEvent}
      rel="noopener noreferrer"
      target="__blank"
    >
      <img alt={badgeAltText} height={80} src={badgeUrl} width={208} />
    </a>
  );
};
