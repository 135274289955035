import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Button,
  Dialog,
  FieldController,
  FormInput,
  FormWrapper,
  ModalTrigger,
} from '@shared/ui-components';
import { ButtonType } from '@shared/utils';

import { useGetNullableCustomerProfile } from '../../../../utils/data/get-customer-profile';
import { type HeaderAccountDropdownContent } from '../../graphql';

import styles from './impersonation-modal.module.scss';

type ImpersonationFormData = {
  personId: string;
};

export const useImpersonationModal = ({
  cmsContent,
}: {
  cmsContent: HeaderAccountDropdownContent;
}) => {
  const [isImpersonationModalVisible, setIsImpersonationModalVisible] = useState(false);
  const { data, update } = useSession();
  const [loading, setLoading] = useState(false);
  const { getNullableCustomerProfile } = useGetNullableCustomerProfile();

  const formMethods = useForm<ImpersonationFormData>({
    defaultValues: {
      personId: '',
    },
    mode: 'onSubmit',
  });

  const closeImpersonationModal = () => {
    setIsImpersonationModalVisible(false);
  };

  const handleSubmit = async (formData: ImpersonationFormData) => {
    setLoading(true);
    const personId = Number(formData.personId);

    const customerProfile = await getNullableCustomerProfile({
      personId,
    });
    if (customerProfile === null) {
      formMethods.setError('personId', { message: cmsContent.impersonateModalErrorMessage });
      setLoading(false);
      return;
    }

    await update({
      personId,
      personIdBeforeImpersonation:
        personId === data?.personIdBeforeImpersonation
          ? undefined
          : data?.personIdBeforeImpersonation ?? data?.personId,
    });
    location.reload();
  };

  return {
    openImpersonationModal: () => setIsImpersonationModalVisible(true),
    renderImpersonationModal: () => (
      <ModalTrigger isOpen={isImpersonationModalVisible}>
        {() => (
          <Dialog
            isLoading={loading}
            onModalClose={closeImpersonationModal}
            title={cmsContent.impersonateModalTitle}
          >
            <FormWrapper
              className={styles['impersonation-form']}
              dataTestId="impersonation-form"
              formMethods={formMethods}
              onSubmit={handleSubmit}
            >
              <FieldController<ImpersonationFormData>
                label={cmsContent.impersonateModalPersonIDLabel}
                name="personId"
              >
                <FormInput type="number" />
              </FieldController>
              <p className={styles['impersonation-form__description']}>
                {cmsContent.impersonateModalDescription}
              </p>
              <div className={styles['impersonation-form__actions']}>
                <Button type={ButtonType.SUBMIT}>{cmsContent.impersonateModalButtonLabel}</Button>
              </div>
            </FormWrapper>
          </Dialog>
        )}
      </ModalTrigger>
    ),
  };
};
