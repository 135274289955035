'use client';

import { ButtonVariant, ChipTheme, LanguageSwitcher, MdrtLogo } from '@shared/ui-components';
import { mapOptimizelyLocaleToIntlLocale } from '@shared/utils';

import { HOMEPAGE_PATH } from '../../../../utils';
import { type LanguagesContent } from '../../../../utils/hooks/get-languages-content';
import { useLanguageSwitcher } from '../../../../utils/hooks/use-language-switcher';
import { type ProfileSummary } from '../../../../utils/types';
import { type NavigationItem } from '../../../../utils/types/navigation-items';
import { AuthenticationButton } from '../../../authentication-button';
import { Button } from '../../../button/button';
import { useGlobalCmsContentContext } from '../../../providers';
import { type HeaderAccountDropdownContent } from '../../graphql';
import { useImpersonationModal } from '../impersonation-modal';
import { Navigation } from '../navigation';
import { ModalTrigger } from '../navigation-mobile';
import { ProfileDropdownItems } from '../profile-dropdown-items';
import { ProfileDropdownSummary } from '../profile-dropdown-summary';
import { StatusChip } from '../stutus-chip';

import styles from './header-content-mobile.module.scss';

type HeaderContentMobileProps = {
  isAuthenticated: boolean;
  isSecondaryTheme: boolean;
  languagesCmsContent: LanguagesContent;
  localeURLSegment?: string;
  navigationItems: NavigationItem[];
  profileDropdownCmsContent: HeaderAccountDropdownContent;
  profileSummary: ProfileSummary;
  statusChipLabel: string | undefined;
  statusChipUrl: string;
};

export const HeaderContentMobile = ({
  isAuthenticated,
  isSecondaryTheme,
  languagesCmsContent,
  localeURLSegment,
  navigationItems,
  profileDropdownCmsContent,
  profileSummary,
  statusChipLabel,
  statusChipUrl,
}: HeaderContentMobileProps) => {
  const { cmsContent: globalCmsContent } = useGlobalCmsContentContext();
  const { onLanguageChange } = useLanguageSwitcher();
  const locale = mapOptimizelyLocaleToIntlLocale(localeURLSegment ?? 'en');
  const { openImpersonationModal, renderImpersonationModal } = useImpersonationModal({
    cmsContent: profileDropdownCmsContent,
  });

  return (
    <>
      {renderImpersonationModal()}
      <div className={styles['header-content-mobile']}>
        <div className={styles['header-content-mobile__logo-container']}>
          <Button
            aria-label={globalCmsContent.goBackToHomePageLabel}
            dataTestId="mobile-mdrt-logo-button"
            to={HOMEPAGE_PATH}
            variant={ButtonVariant.Plain}
          >
            <MdrtLogo className={styles['header-content__logo']} />
          </Button>
        </div>
        <ModalTrigger isSecondaryTheme={isSecondaryTheme}>
          {(close: () => void) => (
            <Navigation
              authenticationSection={
                <AuthenticationButton isAuthenticated={isAuthenticated} isMobile locale={locale} />
              }
              close={close}
              dropdownItemsSlot={
                <ProfileDropdownItems
                  cmsContent={profileDropdownCmsContent}
                  isAuthenticated={isAuthenticated}
                  locale={locale}
                  onClose={close}
                  openImpersonationModal={openImpersonationModal}
                />
              }
              isMobile
              languageSwitcherSlot={
                <LanguageSwitcher
                  currentLocale={localeURLSegment}
                  languagesList={languagesCmsContent.languagesList}
                  onLanguageChange={onLanguageChange}
                  selectLanguageLabel={profileDropdownCmsContent.selectLanguageLabel}
                />
              }
              locale={locale}
              navigationItems={navigationItems}
              profileSummarySlot={
                <ProfileDropdownSummary
                  cmsContent={profileDropdownCmsContent}
                  profileSummary={profileSummary}
                  statusChipSlot={
                    <StatusChip
                      label={statusChipLabel}
                      theme={ChipTheme.Light}
                      url={statusChipUrl}
                    />
                  }
                />
              }
            />
          )}
        </ModalTrigger>
      </div>
    </>
  );
};
