'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { type PropsWithChildren } from 'react';
import { RouterProvider } from 'react-aria-components';

import { GlobalToastContextProvider } from '@shared/ui-components';
import {
  GTM_EVENT_NAME_PDF_DOWNLOAD,
  GTM_EVENT_NAME_OUTBOUND,
  sendGtmEvent,
  GTM_ACTION_DOWNLOAD,
} from '@shared/utils';

import { useRouter } from '../../i18n/i18n-navigation';

import { GlobalLoaderContextProvider } from './global-loader-context';

const makeQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        throwOnError: true,
      },
    },
  });

let browserQueryClient: QueryClient | undefined;

const getQueryClient = () => {
  if (typeof window === 'undefined') {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
};

const handleGTMClickEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  const element = (event.target as HTMLElement).closest('a');
  const isMiddleClick = event.button === 1;

  if (!element || element.tagName !== 'A' || (element.type === 'auxclick' && !isMiddleClick))
    return;

  // NOTE: splitting by ? to get href value without parameters if they exist
  const href = element.href.split('?')[0];

  if (href.endsWith('.pdf')) {
    sendGtmEvent({
      action: GTM_ACTION_DOWNLOAD,
      eventName: GTM_EVENT_NAME_PDF_DOWNLOAD,
      label: element.href.slice(element.href.lastIndexOf('/') + 1),
    });
  }

  if (href.startsWith('http') && !href.includes(window.location.origin)) {
    sendGtmEvent({
      eventName: GTM_EVENT_NAME_OUTBOUND,
      label: element.href,
    });
  }
};

export const Providers = ({ children }: PropsWithChildren) => {
  const queryClient = getQueryClient();
  const router = useRouter();

  return (
    <GlobalToastContextProvider>
      <GlobalLoaderContextProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <RouterProvider navigate={router.push}>
            <div
              onAuxClick={(event) => handleGTMClickEvent(event)}
              onClick={(event) => handleGTMClickEvent(event)}
            >
              {children}
            </div>
          </RouterProvider>
        </QueryClientProvider>
      </GlobalLoaderContextProvider>
    </GlobalToastContextProvider>
  );
};
